/*
 * @Author: sqk
 * @Date: 2021-03-19 16:47:35
 * @LastEditTime: 2022-02-28 17:03:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \B2B\src\views\privacy\index.js
 */
export default {
  components: {},
  data() {
    return {
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/privacy",
          name: "privacy",
          title: "隐私政策"
        }
      ],
      isMobile:false,
    };
  },
  computed: {
  },
  mounted() {
    this.$nextTick(() => {
      $('#privacy-box').css("font-size",  (document.documentElement.clientWidth / 1920)*100 + 'px' );
      const sWidth = document.documentElement.clientWidth;
      if(sWidth < 768){
        this.isMobile = true
      }else{
        this.isMobile = false;
      }
    });
    
  },
  methods: {
    clickNum(id) {
      document.querySelector('#'+id).scrollIntoView(true)

    }
  },
};
